import {
    FETCHING_USER_BALANCES_SUCCESS,
    FETCHING_USER_BALANCES_FAILURE
} from '../actions/types/manage';

const INITIAL_STATE = { finance: { userBalances: null } };

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCHING_USER_BALANCES_SUCCESS:
            return { finance: { userBalances: action.userBalances } };
        case FETCHING_USER_BALANCES_FAILURE:
            return { finance: { userBalances: null, error: true } };
        default:
            return state;
    }
}
