import { FETCHING_USER_BALANCES_SUCCESS, FETCHING_USER_BALANCES_FAILURE } from "./types/manage";
import manageService from '../api/ManageService';

export const getUserBalances = () => {
    return (dispatch) => {
        return manageService.getUserBalances()
            .then((response) => {
                dispatch({
                    type: FETCHING_USER_BALANCES_SUCCESS,
                    userBalances: response
                });
            })
            .catch((error) => {
                dispatch({ type: FETCHING_USER_BALANCES_FAILURE })
            });
    };
}