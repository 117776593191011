import React, { Component } from 'react';

export default class Twizzit extends Component {
    render() {
        return (
            <div>
                <h2>Nieuwe lid en Twizzit info</h2>
                <p><a className="link" target="_blank" href="https://docs.google.com/document/d/1yYK_nUhMQGt2Tzwwngyca30VJVnom9y35GsjxHaiolY/">Nederlands</a></p>
                <p><a className="link" target="_blank" href="https://docs.google.com/document/d/1pSL3Enz035wdhSs9jRefEQwMKuyVq0bHrdBXPbg7MOk/">English</a></p>
            </div >
        )
    }
}
