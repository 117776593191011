import React, { Component } from 'react';
import Agenda from './Agenda';

export default class Trainingsschema extends Component {
	render() {
		return (
			<div>
				<h1>Trainingen</h1>

				<p>Jetset traint op verschillende locaties in Leuven:</p>
				<ul className="normal">
					<li>
						Op het kunstgrasveld A naast het zwembad van Kessel-Lo (Stadionlaan
						4, Kessel-Lo - KLO A)
					</li>
					<li>
						Op het kunstgrasveld B naast het zwembad van Kessel-Lo (Stadionlaan
						4, Kessel-Lo - KLO B)
					</li>
					<li>
						Op het grasveld C naast de parking van het zwembad van Kessel-Lo
						(Stadionlaan 4, Kessel-Lo - KLO C)
					</li>
					<li>
						Op het voetbalveld achterin bij Heilig Hartinstituut (Hertogstraat
						178, Heverlee - HHH)
					</li>
					<li>
						Op het kunstgrasveld aan Bruineveld (Domeinstraat 31, Kessel-Lo -
						BV)
					</li>
					<li>
						In de sporthal Rijschoolstraat (Rijschoolstraat 21, Leuven -
						SPORTHAL RIJSCHOOL)
					</li>
					<li>
						In de sporthal Wijnpers (Mechelsevest 90, Leuven - bovenaan in
						gebouw met klok - DE WIJNPERS)
					</li>
				</ul>
				<p>
					Hieronder vind je per ploeg de meest recente trainingsmomenten en locaties van week tot week.
				</p>
				<Agenda />
			</div>
		);
	}
}
