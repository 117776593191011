
import Request from './Request';
import url from 'url-join';

export default class ManageService {
    static serviceUrl = '/api/manage/';

    static getUserBalances() {
        return Request.authGet(url(ManageService.serviceUrl, '/finance'));
    }
}