import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import image from '../../images/vriendjestraining.png';

export default class Home extends Component {
	render() {
		return (
			<div>
				<div className="give-me-space blog">
					<h2>
						Voorinschrijvingen seizoen 2024-2025
					</h2>
					<p className="normal">
						Heb jij onze posters gezien, en ben je getriggerd?
						Of was je altijd al benieuwd naar ultimate frisbee?
						Ben je nog op zoek naar een nieuwe sport voor jou en/of je kind(eren)?
						En hou je van een dynamische, explosieve en unieke teamsport?
						Dan willen wij je graag verwelkomen in onze club.
						We bieden ultimate frisbee aan voor groot en klein (meer info onder de tekening).
						Heb je interesse? Laat dan <a className='link' href="https://docs.google.com/forms/d/e/1FAIpQLSdfhqQBAY-mnrRaTO1RXQiFMt5__EjFciKoVpv9_brtcYfJwA/viewform">hier</a> je gegevens achter, zodat we je kunnen contacteren voor de start van het nieuwe seizoen in september. Tot binnenkort!
					</p>

					<h2>
						Elite Invite
					</h2>
					<p className="normal">
						From the 10th to 12th of May Jetset is organizing Elite Invite in collaboration with Stad Leuven and EUF. This tournament will bring together the top 8 European teams in the 3 divisions (Mixed, Open and Women). Free entry to come check how LUV and Jetset Open fare against the highest level of ultimate ever displayed in Leuven.
					</p>
					<b>
						Practicals
					</b>
					<p className='normal'>Date: 10-12/05/2024 9h-20h (16h on Sunday) <br /> Address: Domeinstraat 61, 3010 Kessel-Lo</p>

					
					<h2>Open trainingen U11, U14/U17, U20 en volwassenen</h2>
					<p className="normal">
						Ben je op zoek naar een nieuwe sport voor jezelf of jouw kinderen? Ben je nog niet zeker of het iets voor jou of voor jouw kinderen is? In april, mei en juni kunnen jouw kinderen gratis komen trainen tijdens onze vriendjes training. Bekijk in deze flyer waar en wanneer jouw kind kan aansluiten. Voor kinderen geboren in 2014-2016 gaan deze trainingen door op de sportvelden van het Heilig Hart: Kerspelstraat 70 3001 Heverlee. Voor kinderen onder de 14 jaar (2011-2013) en onder de 17 jaar (2008-2010) gaat dit door aan het Boudewijnstadion in Kessel-Lo: Stadionlaan 8, 3010 Leuven.
					</p>
					<img src={image} alt="jetset-vriendjes-training" style={{ width: '100% ' }} />
					<p className="normal"> In september kan je als U20 of volwassene een hele maand gratis komen uitproberen! 
					Heb je interesse? Laat dan <a className='link' href="https://docs.google.com/forms/d/e/1FAIpQLSdfhqQBAY-mnrRaTO1RXQiFMt5__EjFciKoVpv9_brtcYfJwA/viewform?usp=sf_link">hier</a> je gegevens achter, zodat we je kunnen contacteren voor de start van het nieuwe seizoen in september. Tot binnenkort!
					</p>

					<h2>In welke ploeg kom je terecht? (seizoen 2024-2025) </h2>
					<b>U11 - Balloons (geboren in 2015-2017):</b>
					<p> 
						De U11 wordt binnen onze club de Balloons genoemd. Kinderen zijn welkom van zodra ze 8 jaar oud zijn. De U11 komen elke week samen op maandag om binnen te trainen en donderdag buiten. Hier worden spelenderwijs de verschillende worpen en spelvormen aangeleerd.
					</p>
					<b>U14 - ULM (geboren in 2012-2014):</b>
					<p>
						De U14 wordt binnen onze club de ULM genoemd. Zij trainen op dinsdag en donderdag. Hier wordt al meer gefocust op tactieken. Enkele keren per jaar wordt meegedaan aan toernooien.
					</p>
					<b>U17 - ULM Alpha Jet (geboren in 2009-2011):</b>
					<p>
						De U17 wordt binnen onze club Alpha Jet genoemd. Zij trainen op dinsdag en op donderdag. Zij werken meer naar toernooien toe.
					</p>
					<b>U20 (geboren in 2006-2008):</b>
					<p>
						Vanaf de U20 staat het competitieve centraal. Zij hebben training op donderdag. Ze kunnen naargelang hun niveau een tweede training met de volwassenen volgen.
					</p>
					<b>Zeppelins (geboren in 2005 of ouder):</b>
					<p>Dit is onze beginners ploeg voor volwassenen. Hier starten nieuwe leden. De trainingen gaan door op dinsdag. Hier wordt gefocust op leren gooien en een introductie tot de regels en tactieken van het spel. Voor nog meer uitleg over de verschillende teams kan je {' '}
						<Link className='link' to="/ploegen">hier</Link> terecht.</p>
					<h2>Wanneer train je?</h2>
					<p>Je kan alle trainingsmomenten terugvinden in de <Link className='link' to="/trainingsschema">agenda</Link>.</p>

				</div>
			</div >
		);
	}
}
