import React, { Component } from 'react';

export default class SpiritOfTheGame extends Component {
	render() {
		return (
			<div>
				<h1>Spirit of the game</h1>
				<p>
					Spirit of the Game is één van de basisprincipes van ultimate. Het is
					vergelijkbaar met fair play en sportiviteit, maar in ultimate wordt
					dit nog meer benadrukt. Dit wordt samengevat in de regels van het
					spel:
				</p>
				<p className="quote">
					Alle spelers zijn verantwoordelijk voor het kennen en toepassen van de
					regels van het spel. Ultimate vertrouwt op de spirit of the game dat
					de verantwoordelijkheid van fair play legt bij de spelers. We
					vertrouwen erop dat niemand opzettelijk de regels breekt, daarom zijn
					er geen harde straffen voor, maar eerder een methode om het spel te
					hervatten alsof de overtreding niet gebeurd zou zijn. Hoge
					competitiviteit is aangemoedigd maar mag nooit invloed hebben op het
					wederzijdse respect tussen de spelers of de vreugde van het spel.
				</p>
				<p>
					Acties zoals opzettelijk fouten maken, vals spelen, gevaarlijk spel,
					onrespectvolle communicatie en ander gedrag om
					<span className="quote">kost wat kost te winnen</span> staan in
					tegenstelling tot de spirit of the game. De integriteit van de sport
					steunt op de verantwoordelijkheid van alle spelers om de spirit hoog
					te houden.
				</p>
				<p>
					Omdat ultimate een sport is zonder scheidsrechter, is het belangrijk
					om de spirit hoog te houden. Spelers moeten de regels van het spel
					kennen, eerlijk zijn, de tegenstander een kans geven om zijn/haar
					uitleg te geven en om geschillen op te lossen op een snelle en
					respectvolle manier.
				</p>
				<h2>Voorbeelden van goede spirit:</h2>
				<ul className="normal">
					<li>
						Een teamgenoot informeren als je denkt dat hij/zij een verkeerde of
						onnodige<span className="quote">call</span> maakt of zelf een
						fout/inbreuk maakt
					</li>
					<li>
						Een<span className="quote">call</span> terugtrekken als jij niet
						meer gelooft dat deze van toepassing is
					</li>
					<li>
						Een tegenstander een compliment geven voor een goede actie of spirit
					</li>
					<li>Jezelf voorstellen bij de tegenstander</li>
					<li>Kalm reageren tegenover onenigheden of provocatie</li>
				</ul>
				<iframe
					id="spirit-video"
					title="spirit"
					src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FBelgianUltimate%2Fvideos%2F890232941136288%2F&show_text=0&width=560"
					width="100%"
					height="500"
					allowFullScreen={true}
				></iframe>
			</div>
		);
	}
}
