export const REQUEST_LOADING = 'REQUEST_LOADING';
export const REQUEST_DONE = 'REQUEST_DONE';
export const REQUEST_FAILED = 'REQUEST_FAILED';
export const CLEAR_API_STATES = 'CLEAR_API_STATES';
export const CLEAR_OTHER_API_STATES = 'CLEAR_OTHER_API_STATES';

export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNIN_USER_FAILURE = 'SIGNIN_USER_FAILURE';

export const LOGOUT_USER = 'LOGOUT_USER';

export const REQUESTING_RESET_PASSWORD = 'REQUESTING_RESET_PASSWORD';
export const REQUESTING_RESET_PASSWORD_SUCCESS = 'REQUESTING_RESET_PASSWORD_SUCCESS';
export const REQUESTING_RESET_PASSWORD_FAILURE = 'REQUESTING_RESET_PASSWORD_FAILURE';

export const RESETTING_PASSWORD = 'RESETTING_PASSWORD';
export const RESETTING_PASSWORD_SUCCESS = 'RESETTING_PASSWORD_SUCCESS';
export const RESETTING_PASSWORD_FAILURE = 'RESETTING_PASSWORD_FAILURE';

export const FETCHING_PAYMENTS = 'FETCHING_PAYMENTS';
export const FETCHING_PAYMENTS_SUCCESS = 'FETCHING_PAYMENTS_SUCCESS';
export const FETCHING_PAYMENTS_FAILURE = 'FETCHING_PAYMENTS_FAILURE';

export const USER_FROM_TOKEN = 'USER_FROM_TOKEN';
export const USER_FROM_TOKEN_SUCCESS = 'USER_FROM_TOKEN_SUCCESS';
export const USER_FROM_TOKEN_FAILURE = 'USER_FROM_TOKEN_FAILURE';

export const FETCHING_TEAMS = 'FETCHING_TEAMS';
export const FETCHING_TEAMS_SUCCESS = 'FETCHING_TEAMS_SUCCESS';
export const FETCHING_TEAMS_FAILURE = 'FETCHING_TEAMS_FAILURE';

export const FETCHING_TOURNAMENTS = 'FETCHING_TOURNAMENTS';
export const FETCHING_TOURNAMENTS_SUCCESS = 'FETCHING_TOURNAMENTS_SUCCESS';
export const FETCHING_TOURNAMENTS_FAILURE = 'FETCHING_TOURNAMENTS_FAILURE';

export const FETCHING_USER_TOURNAMENTS = 'FETCHING_USER_TOURNAMENTS';
export const FETCHING_USER_TOURNAMENTS_SUCCESS = 'FETCHING_USER_TOURNAMENTS_SUCCESS';
export const FETCHING_USER_TOURNAMENTS_FAILURE = 'FETCHING_USER_TOURNAMENTS_FAILURE';

export const FETCHING_TOURNAMENT_INFO_AND_SUBSCRIPTION = 'FETCHING_TOURNAMENT_INFO_AND_SUBSCRIPTION';
export const FETCHING_TOURNAMENT_INFO_AND_SUBSCRIPTION_SUCCESS = 'FETCHING_TOURNAMENT_INFO_AND_SUBSCRIPTION_SUCCESS';
export const FETCHING_TOURNAMENT_INFO_AND_SUBSCRIPTION_FAILURE = 'FETCHING_TOURNAMENT_INFO_AND_SUBSCRIPTION_FAILURE';

export const ADDDING_TOURNAMENT = 'ADDDING_TOURNAMENT';
export const ADDDING_TOURNAMENT_SUCCESS = 'ADDDING_TOURNAMENT_SUCCESS';
export const ADDDING_TOURNAMENT_FAILURE = 'ADDDING_TOURNAMENT_FAILURE';

export const UPDATING_TOURNAMENT = 'UPDATING_TOURNAMENT';
export const UPDATING_TOURNAMENT_SUCCESS = 'UPDATING_TOURNAMENT_SUCCESS';
export const UPDATING_TOURNAMENT_FAILURE = 'UPDATING_TOURNAMENT_FAILURE';

export const SUBSCRIBING_FOR_TOURNAMENT = 'SUBSCRIBING_FOR_TOURNAMENT';
export const SUBSCRIBING_FOR_TOURNAMENT_SUCCESS = 'SUBSCRIBING_FOR_TOURNAMENT_SUCCESS';
export const SUBSCRIBING_FOR_TOURNAMENT_FAILURE = 'SUBSCRIBING_FOR_TOURNAMENT_FAILURE';


export const UNSUBSCRIBING_FOR_TOURNAMENT = 'UNSUBSCRIBING_FOR_TOURNAMENT';
export const UNSUBSCRIBING_FOR_TOURNAMENT_SUCCESS = 'UNSUBSCRIBING_FOR_TOURNAMENT_SUCCESS';
export const UNSUBSCRIBING_FOR_TOURNAMENT_FAILURE = 'UNSUBSCRIBING_FOR_TOURNAMENT_FAILURE';

export const FETCHING_PLAYER_SUBSCRIPTIONS = 'FETCHING_PLAYER_SUBSCRIPTIONS';
export const FETCHING_PLAYER_SUBSCRIPTIONS_SUCCESS = 'FETCHING_PLAYER_SUBSCRIPTIONS_SUCCESS';
export const FETCHING_PLAYER_SUBSCRIPTIONS_FAILURE = 'FETCHING_PLAYER_SUBSCRIPTIONS_FAILURE';

export const ACCOUNT_UPDATE_BASIC_INFO = 'ACCOUNT_UPDATE_BASIC_INFO';
export const ACCOUNT_UPDATE_PLAYER_INFO = 'ACCOUNT_UPDATE_PLAYER_INFO';
