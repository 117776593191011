import React from 'react';
import { Col } from 'react-bootstrap';
import {
	Card,
	CardImg,
	CardText,
	CardBody,
	CardTitle,
	CardSubtitle
} from 'reactstrap';

const ContactGroup = props => {
	const { title, name, contactInfo, phoneInfo, responsibilities, imageSrc, imageLink } = props;
	return (
		<Col xs={12} md={5} lg={4} className="contact-group-container">
			<Card className="contact-card">
				{imageSrc && (
					<div className="contact-img">
						{imageLink ? <a href={imageLink}><CardImg top src={imageSrc} /></a> : <CardImg top src={imageSrc} />}
					</div>
				)}
				<CardBody>
					<CardTitle>{title}</CardTitle>
					<CardSubtitle>{name}</CardSubtitle>
					<CardText className="contact-text">
						{responsibilities && (
							<span className="contact-text-responsibilities">
								{responsibilities}
							</span>
						)}
						{contactInfo && (
							<div className="contact-text-contactInfo"><a href={`mailto:${contactInfo}`}>{contactInfo}</a></div>
						)}
							{phoneInfo && (
							<div className="contact-text-contactInfo"><a href={`tel:${phoneInfo}`}>{phoneInfo}</a></div>
						)}
					</CardText>
				</CardBody>
			</Card>
		</Col>
	);
};

export default ContactGroup;
