import React, { Component } from 'react';

import SocialLink from '../../components/SocialLink';

export default class SocialHeader extends Component {
	render() {
		return (
			<div className="top-navigation-right">
				<div className="top-social-wrapper">
					<SocialLink
						link="https://www.instagram.com/jetsetultimate/"
						iconName="instagram"
					/>
					<SocialLink
						link="https://www.facebook.com/UltimateFrisbeeLeuven"
						iconName="facebook"
					/>
				</div>
			</div>
		);
	}
}
