import React, { Component } from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import ploegenJeugd from '../../images/PloegenJeugd.png';
import ploegenVolwassenen from '../../images/PloegenVolwassenen.png';

export default class Ploegen extends Component {
	render() {
		return (
			<div>
				<h1>Ploegen</h1>
				<p>
					<span className='highlight'>Interesse om mee te sporten? </span>
					Hieronder maak je alvast kennis met de
					<b> verschillende ploegen</b> binnen onze club. Er zijn verschillende
					jeugdploegen en volwassenploegen naargelang je leeftijd, niveau en
					competitiviteit. De <b>trainingsmomenten en - locaties</b> kan je
					{' '}<Link className='link' to='/trainingsschema'>
						hier
					</Link>{' '}
					vinden.
					<br />
					Neem op training sportkleren, (voor outdoor) voetbalschoenen (met
					plastic studs) en een drinkbus mee. Een outfit kan na de start van het
					seizoen besteld worden.
				</p>

				<h2>NIEUWE LEDEN</h2>
				<p>
					Nieuwe leden kunnen in september komen testen en dienen voor eind september officieel in te schrijven via {' '}
					<a className="link" href="https://static.twizzit.com/v2/public/form/4c9ff4ee525a0fbb75f606698c4d04f0">
					Twizzit
					</a>
					<br />
					Informatie over lidgeld vind je onder
					{' '}<Link className='link' to='/register'>
						<span data-hover='nieuw lid'>nieuw lid</span>
					</Link>.
					<br />
					Leden die halverwege het jaar nog willen aansluiten, mailen best
					naar het
					{' '}<Link className='link' to='/contact'>
						bestuur
					</Link>.
				</p>

				<h2>JEUGDPLOEGEN</h2>
				<p>
					Onze <b>jeugd</b> die volledig achter de
					{' '}<a
						className="link"
						href='http://panathlonvlaanderen.be/'
						target='_blank'
						rel='noopener noreferrer'
					>
						panathlon-verklaring
					</a>{' '}
					staat, is opgestart in 2015. We werken met de leeftijdscategorieën volgens de
					(inter)nationale wedstrijden, namelijk U11, U14, U17 en U20. Voor dit
					seizoen zijn er volgende jeugdploegen:
				</p>

				<ul className='normal'>
					<li>
						<b className="highlight" id="u11-balloons">U11 - Balloons</b>:
						De U11 wordt binnen onze club de Balloons genoemd. Kinderen zijn welkom van zodra ze 8 jaar oud zijn. De U11 komen elke week samen op maandag om binnen te trainen en donderdag buiten (zie
						{' '}<Link className='link' to='/trainingsschema'>
							agenda
						</Link>). Hier worden spelenderwijs de verschillende worpen en spelvormen aangeleerd.
					</li>
					<li>
						<b className="highlight" id="u14-ulm">U14 - ULM</b>: 
						De U14 wordt binnen onze club de ULM genoemd. Zij trainen op dinsdag en donderdag (zie
						{' '}<Link className='link' to='/trainingsschema'>
							agenda
						</Link>). Hier wordt al meer gefocust op tactieken. Enkele keren per jaar wordt meegedaan aan toernooien.
					</li>
					<li>
						<b className="highlight" id="u17-ulm">U17 - ULM Alpha Jet</b>: De U17 wordt binnen onze club Alpha Jet genoemd. Zij trainen op dinsdag en op donderdag. Zij werken meer naar toernooien toe.
					</li>
					<li>
						<b className="highlight" id="u20">U20 </b>: Vanaf de U20 staat het competitieve centraal. Zij hebben training op donderdag (zie
						{' '}<Link className='link' to='/trainingsschema'>
							agenda
						</Link>). Ze kunnen naargelang hun niveau een tweede training met de volwassenen volgen.
					</li>
					<li>
						<b className="highlight" id="zeppelins">Zeppelins </b>: Dit is onze beginners ploeg voor volwassenen. Hier starten nieuwe leden. De trainingen gaan door op dinsdag (zie
						{' '}<Link className='link' to='/trainingsschema'>
							agenda
						</Link>). Hier wordt gefocust op leren gooien en een introductie tot de regels en tactieken van het spel. Voor nog meer uitleg over de verschillende teams kan je {' '}
						<Link className='link' to="/ploegen"> hier</Link> terecht.
					</li>
				</ul>
				<Image src={ploegenJeugd} responsive className='img' />

				<h2>VOLWASSENPLOEGEN</h2>
				<p>
					Bij de <b>volwassenen</b> worden de ploegen opgesplitst op basis van
					niveau. Ook zijn er onderverdelingen op basis van geslacht, namelijk
					open, vrouwen en mixed.
				</p>
				<ul className='normal'>
					<li>
						<b>Beginners:</b> Zeppelins (open), LUV (vrouwen)
					</li>
					<li>
						<b>Gemiddeld:</b> Propellers (open), LUV (vrouwen), Gold
					</li>
					<li>
						<b>Expert:</b> Jetset (open), LUV (vrouwen), <span id="mixed">Mixed (mixed)</span>
					</li>
				</ul>
				<p>
					<span className='highlight'>
						De <b id="zeppelins">Zeppelins</b> is onze beginnersploeg.{' '}
					</span>
					Hier starten nieuwe leden. De trainingen gaan door op dinsdag (zie
					{' '}<Link className='link' to='/trainingsschema'>
						agenda
					</Link>
					). Hier wordt gefocust op leren gooien en uitleg van het spel.
					Voor KUL-studenten is er de mogelijkheid op een tweede trainingsmoment via de KULeuven
					trainingen. Beginnende vrouwen worden gestimuleerd om ook naar de
					LUV-training te gaan (zie verder).
					<br />
					<br />
					<span className='highlight'>
						De <b id="propellers">Propellers</b> vormen het middenniveau van onze club.{' '}
					</span>
					De training gaat door op donderdag (zie
					{' '}<Link className='link' to='/trainingsschema'>
						agenda
					</Link>
					). Hier wordt meer rond tactieken gewerkt. Zij gaan vaker naar
					officiële en funtoernooien. Ook hier worden de vrouwen gestimuleerd om
					naar de LUV-training te gaan (zie verder). Voor de spelers die meer op
					zoek zijn voor een sport als recreatie, is er <b id="gold">Gold</b> waar
					spelplezier centraal staat. Hier kunnen spelers die de basis goed
					beheersen spelen, dus enige ervaring is wel vereist.
					<br />
					<br />
					<span className='highlight'>
						Ten slotte is er <b id="jetset">Jetset (open)</b> waarin onze topspelers
						trainen.{' '}
					</span>
					Dit op maandag en donderdag (zie
					{' '}<Link className='link' to='/trainingsschema'>
						agenda
					</Link>
					). Zij behoren tot de top van de Belgisch frisbeewereld. Daarnaast is
					er ook een mixed-ploeg en een vrouwenploeg LUV.
					<br />
					<br />
					<span className='highlight'>
						Voor de <b id="luv">vrouwen</b> worden aparte trainingsmomenten voorzien.{' '}
					</span>
					LUV, ofwel Leuvense Ultimate Vrouwen, geeft alle vrouwen uit de club
					de kans om samen te trainen en zich voor te bereiden op
					vrouwen-toernooien. Voor de volwassenen wordt op maandag getraind.
				</p>
				<Image src={ploegenVolwassenen} responsive className='img' />
				<h2>WEDSTRIJDEN & TOERNOOIEN</h2>

				<p>
					In België worden wedstrijden op nationaal niveau gehouden. Dit wilt
					zeggen dat de afstanden groot zijn en de wedstrijden in
					<b> toernooivorm</b> worden georganiseerd. Sommige toernooien duren
					één dag maar de meesten zijn gedurende een heel weekend. Eenmaal lid,
					kan je op de ledenzone kijken welke toernooien je kan meedoen. Er
					bestaan funtoernooien waar je als team of als persoon kan inschrijven.
					Zo komt iedereen aan zijn trek! De kosten voor een toernooi zijn niet
					verrekend in het lidgeld en worden via de ledenzone aangerekend.
					<br />
					In België zijn er Belgian Ultimate (Open/Women/Youth) Outdoor en
					Indoor Championships, afgekort BU(W/Y)OC en BU(W/Y)IC.
				</p>
			</div>
		);
	}
}
