import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../images/JS_Wings_BLK.svg'

import { Image } from 'react-bootstrap';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import SocialHeader from './SocialHeader'
import MembersHeader from './MembersHeader';
import Icon from '../../components/Icon';


class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        return (
            <div>
                <div className="brand">
                    <Link to="/">
                        <Image src={logo} responsive />
                    </Link>
                </div>
                <SocialHeader />
                <Navbar expand="sm">
                    <NavbarBrand className="d-block d-sm-none" href="/">Jetset Ultimate</NavbarBrand>
                    <NavbarToggler onClick={this.toggle}>
                        <Icon icon={faBars} />
                    </NavbarToggler>

                    <Collapse navbar isOpen={this.state.isOpen}>
                        <Nav className="ml-auto" navbar>
                            <NavItem>
                                <NavLink tag={Link} to="/">Home</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/frisbeeclub">Club</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/spiritofthegame">Spirit of the game</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/ploegen">Ploegen</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/trainingsschema">Trainingsschema</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/contact">Contact</NavLink>
                            </NavItem>
                            {/* <MembersHeader /> */} 

                        </Nav>

                    </Collapse>
                </Navbar>

            </div >
        )
    }
}


export default Header;
