import moment from 'moment';

export function ableToUnsubscribe(startDate) {
    const startDateMoment = moment(startDate)
    const today = moment();

    return today.isBefore(startDateMoment);
}

export function ableToSubscribe(startDate, openDate) {
    const openDateMoment = moment(openDate);
    const startDateMoment = moment(startDate)

    const today = moment();

    return today.isSameOrAfter(openDateMoment) && today.isBefore(startDateMoment);
}

export function ableToUpdateSubscription(startDate) {
    return ableToUnsubscribe(startDate);
}


export function isSubscribedForTournament(tournament) {
    return getOpenSubscriptions(tournament).length > 0;
}

export function getOpenSubscriptions(tournament) {

    return tournament.Subscriptions.filter(tournament => { return tournament.Cost && !tournament.Refund });
}

export function getOpenSubscription(tournament) {

    const openSubscriptions = getOpenSubscriptions(tournament);

    if (openSubscriptions[0]) {
        return openSubscriptions[0];
    }
    return;
}

