import React, { Component } from 'react';
// import { Grid, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux'

import ContentCard from '../Content/ContentCard';
import ContentCardBody from '../Content/ContentCardBody';
import TransactionOverview from '../MyFinance/Transactions/TransactionOverview';
import verzekeringsPDFNL from '../../assets/Verzekeringsformulier_NL.pdf';
import verzekeringsPDFEN from '../../assets/Verzekeringsformulier_EN.pdf';

class Dashboard extends Component {

    render() {
        return (
            <ContentCard>
                <ContentCardBody>
                    <h1>Overzicht</h1>
                    <div>
                        <p>Hallo {this.props.user.firstName}!</p>
                        <TransactionOverview />

                        <h3>Nuttige links en documenten</h3>
                        <p>Hier kan je alles vinden wat je nodig hebt bij een ongeval of voor de mutualiteit. Je kan hier ook het archief met de nieuwsbrieven terugvinden en de verslagen van de bestuurs- en trainersvergaderingen.</p>
                        <ul className="normal">
                            <li><b>Verzekering:</b> Heb je een ongeval gehad op tornooi, training, activiteit of tijdens het vervoer? Je kan <a href={verzekeringsPDFNL} target="_blank" rel="noopener noreferrer">hier</a> (or English form <a href={verzekeringsPDFEN} target="_blank" rel="noopener noreferrer">here</a>) het verzekeringsformulier downloaden dat je bij de dokter kan laten invullen. Het lidnummer mag leeg gelaten worden. </li>
                            <li><b>Mutualiteit:</b> Hiervoor mag je mailen naar info@jetsetultimate.be, de voorzitter helpt je verder.</li>
                            <li><b>Nieuwsbrieven:</b> Het archief van onze nieuwsbrieven staat <a href="https://drive.google.com/drive/folders/0B0sEoNoc1V9Fa2lPVlNZZmxRTXM?resourcekey=0-FPYYwljXGXjB8wjHaBEzPg&usp=sharing" target="_blank" rel="noopener noreferrer">hier</a>.</li>
                            <li><b>Verslagen:</b>Ben je benieuwd wat er op de bestuurs- of trainersvergaderingen wordt besproken? Je kan <a href="https://drive.google.com/drive/folders/1zVv67EoKFCDjLMwGZC33s6Rzpyyv5P9z?usp=sharing" target="_blank" rel="noopener noreferrer">hier</a> de verslagen terugvinden.</li>
                        </ul>

                    </div>
                </ContentCardBody>
            </ContentCard>
        );
    }
}

function mapStateToProps(state) {

    const { user } = state.userData;

    return {
        user
    }
}
const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
