import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Twizzit from './Twizzit.js';

export default class Register extends Component {
	render() {
		return (
			<div>
				<h2 className="login-header">Maak een account</h2>
				<p>
					Dag potentieel nieuw lid van onze club! We zijn blij dat je bij ons in
					de club wilt komen! Om te weten te komen in welke ploeg je hoort, neem
					zeker een kijkje bij {' '}<Link className='link' to="/ploegen">ploegen</Link>{' '}
					zodat je je juist kan inschrijven en je weet waar en wanneer de
					trainingen plaatsvinden (onder
					{' '}<Link className='link' to="/trainingsschema">kalender</Link>).
				</p>

				<table id="tg">
					<tbody>
						<tr>
							<th className="tg-bgyt">Start</th>
							<th className="tg-bgyt">Student</th>
							<th className="tg-bgyt">Niet-student</th>
						</tr>
						<tr>
							<td className="tg-s6z2">September</td>
							<td className="tg-s6z2">€175</td>
							<td className="tg-s6z2">€200</td>
						</tr>
						<tr>
							<td className="tg-s6z2">Januari</td>
							<td className="tg-s6z2">€135</td>
							<td className="tg-s6z2">€135</td>
						</tr>
						<tr>
							<td className="tg-s6z2">Mei</td>
							<td className="tg-s6z2">€70</td>
							<td className="tg-s6z2">€70</td>
						</tr>
						<tr>
							<td className="tg-s6z2">Extern</td>
							<td className="tg-s6z2">€165</td>
							<td className="tg-s6z2">€165</td>
						</tr>
					</tbody>
				</table>
				<p>
					Het lidgeld kan je storten op ons rekeningnummer: <b>BE25 7360 3918 0182.</b>
				</p>
				<p>Het lidgeld omvat volgende aspecten:</p>
				<ul className="normal">
					<li>Verzekering</li>
					<li>Trainingen, velden en trainers</li>
					<li>Nieuwsbrief elke maand</li>
					<li>Teamfee op alle Belgische kampioenschappen</li>
					<li>Inschrijving bij Frisbee Vlaanderen</li>
					<li>Indien nieuwe jeudspeler: een gratis frisbee</li>
				</ul>

				<Twizzit />
			</div>
		);
	}
}
