import image from './2020_bumac_jetset_gold.png';

export default {
	title: 'Jetset Gold op BUMAC',
	content:`
		<p>Na maanden zonder toernooien of andere wedstrijden mochten de oude knarren hun roestige gewrichten eindelijk nog eens insmeren, op zondag 13 september stond namelijk het Belgische Master Kampioenschap op het menu! Een meute vrolijke Goldies trokken hiervoor graag naar Antwerpen met een gevulde frigobox. Omwille van gekende redenen beloofde het namelijk een nieuwe tornooi-ervaring te worden: geen bar om samen aan te hangen met iedereen die we al lang niet meer gezien hadden, geen knuffels en kusjes, geen high-fives, geen spirit-circles, … Maar WEL ultimate zoals we het al lang niet meer konden spelen!</p>
		<p>8 ploegen traden aan in dit kampioenschap, onderverdeeld in 2 poules. Aangezien Jetset Gold een ploeg had samengesteld met spelers waarvan sommigen al lang niet meer samen gespeeld hadden waren we realistisch in onze ambities en was het hoofddoel genieten van het feit dat we nog eens een schijf mochten smijten.</p>
		<p>Eerste match tegen de Rabbits waren we sterk begonnen en toen kwamen ineens die Rabbits toch wel sterk opzetten. Na een comeback maakten ze het nog af ook op universe. Een pijnlijk verlies maar wel een zeer leuke start. Tegen XL en de Schijnwerpers was vooral het fysieke overwicht doorslaggevend en deze matchen hebben we ook niet winnend kunnen afsluiten. Op naar de laatste match dan om een leuk dagje winnend te kunnen afsluiten! Zo gezegd, zo gedaan, de Obsowletes hebben we klein gekregen na een goede wedstrijd en een mooie zevende plek was ons deel.</p>
		<p>Al bij al was dit een zeer leuk intermezzo in deze gekke tijden met dank aan iedereen die dit ondanks de huidige beperkingen toch wil organiseren. Desalniettemin hunkeren we allemaal naar een toernooi waar je samen iets kan drinken met de vrienden uit de andere ploegen, zonder vak waarin je moet blijven zitten tussen de wedstrijden, en een goed feestje om de benen los te dansen na een stevig dagje Ultimate. Dat laatste werd duidelijk gemist de volgende dag…</p>
	`,
	image: image
};
