import { } from 'redux';

import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../../components/Loader/Loader';
import ContentCard from '../../Content/ContentCard';
import ContentCardBody from '../../Content/ContentCardBody';
import { getUserBalances } from '../../../store/actions/manage';

const renderContent = ({ error, userBalances }) => {
    if (error) {
        return <div>Gegevens konden niet worden geladen</div>;
    }
    else if (userBalances) {
        return (
            <table className="table">
                <thead><tr>
                    <th>Naam</th>
                    <th>Voornaam</th>
                    <th>Balans</th>
                </tr>
                </thead>
                <tbody>
                    {userBalances.map((user) => {
                        return <tr>
                            <td>{user.firstName}</td>
                            <td>{user.lastName}</td>
                            <td>{user.balance}</td>
                        </tr>
                    })}
                </tbody>

            </table>
        )
    }
    else {
        return <Loader />
    }
}

const ManageFinance = ({ }) => {

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getUserBalances())
    }, [])

    const financeInfo = useSelector(store => store.manage.finance);


    return <ContentCard>
        <ContentCardBody>
            <h1>Financieel overzicht</h1>
            {renderContent(financeInfo)}
        </ContentCardBody>
    </ContentCard>
}

export default ManageFinance
