import React, { Component } from 'react';

export default class Agenda extends Component {
	render() {
		return (
			<div>
				<h2>Kalender</h2>
				<p>
					Ook activiteiten voor de hele club staan hier op. Indien je wil kan je aan
					de hand van het plusje onderaan de agenda, deze toevoegen aan je eigen
					agenda. Zo ben je nooit te laat.
				</p>
				<iframe
					title="agenda"
					src="https://calendar.google.com/calendar/b/1/embed?mode=WEEK&amp;height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=Europe%2FBrussels&amp;src=amV0c2V0dWx0aW1hdGVmcmlzYmVlQGdtYWlsLmNvbQ&amp;src=YnF1dHJ1cDNzaWZvM2FhcGcxanN2bGFtdjhAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=azVnaWxsbXJjN281OGdhYTQ5Nm1vYWI0MWdAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=MGd2bmltY204b3BkMGtxanRtZ2lkbXJmZ2NAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=cHUzYW5sYjRkcjVrbWdwaDFlZHFyOG9odmtAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=aWFqazExNmtyMDhnaTNyaWM1cGo0aTJzcmtAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=MGpwZW01NWphZDgxNXNndTN0cWJ2YzQ0b2dAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=ZWw5MWMxc205NG4xOGthNmtucXE5YjFrazBAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=Y2IxMWVnaTFhbG5ndmZxNDhhNXViODBwbXNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=ZXV2azA5MHNyaDljaWRhMHZycXY2OXZma3NAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=MjdoOGV1ZWd2bGMxdjNnNDBpZGJrYWVzbjBAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=azA4dTg3b2ljYnFqdmo3a2ViZjUxdXIwbzRAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;color=%23515151&amp;color=%23AA5A00&amp;color=%231F753C&amp;color=%2330487E&amp;color=%238A2D38&amp;color=%235A9A08&amp;color=%23336699&amp;color=%239F3501&amp;color=%23D6AE00&amp;color=%239D7000&amp;color=%238A2D38&amp;color=%2370237F"
					width="100%"
					height="600"
					frameBorder="0"
					scrolling="no"
				></iframe>
			</div>
		);
	}
}
