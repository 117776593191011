import { } from '@fortawesome/free-regular-svg-icons';

import { faCrown, faEuroSign, faGamepad, faHome, faUser, faUserAstronaut, faUsers } from '@fortawesome/free-solid-svg-icons';

import Dashboard from './Dashboard/Dashboard';
import ManageFinance from './Manage/Finance/ManageFinance';
import MyFinance from './MyFinance/MyFinance';
import NotFoundOrUnauthorized from './ErrorPages/NotFoundOrUnauthorized';
import Profile from './Profile/Profile';
import React from 'react';
import TournamentManagement from './TournamentManagement/TournamentManagement';
import Tournaments from './Tournaments/Tournaments';

// import eee from '@fortawesome/fontawesome-free';

export default [
    {
        path: "/",
        label: 'general',
        routes: [
            {
                path: "",
                label: "dashboard",
                component: () => <Dashboard />,
                exact: true,
                icon: faHome,
                authenticated: true
            },
            {
                path: "profile",
                label: "Profiel",
                component: Profile,
                icon: faUser,
                authenticated: true
            },
            {
                path: "finance",
                label: "My finance",
                component: MyFinance,
                exact: true,
                icon: faEuroSign,
                authenticated: true
            }
        ]
    },
    {
        path: "/tournaments",
        label: 'tournaments',
        authenticated: true,
        routes: [
            {
                path: "/",
                label: "overview",
                component: Tournaments,
                icon: faGamepad

            }
        ]
    },
    {
        path: "/manage",
        label: "Manage",
        roles: ['admin', 'trainer', 'finance'],
        routes: [
            {
                path: "/tournaments/",
                label: "Tournament management",
                roles: ['trainer'],
                component: TournamentManagement,
                icon: faCrown
            },
            {
                path: "/finance",
                label: "Finance",
                roles: ['finance'],
                component: ManageFinance,
                icon: faEuroSign
            }
        ]
    },
    {
        path: "/admin",
        label: "admin",
        component: () => <div>admin</div>,
        roles: ['admin'],
        routes: [
            {
                path: "/users",
                label: "User management",
                component: () => <div>users overview</div>,
                exact: true,
                icon: faUsers
            }
        ]
    },
    {
        path: "*",
        component: NotFoundOrUnauthorized
    }
];
