import React, { Component } from 'react';

class PermissionDenied extends Component {

    render() {
        return (
            <div>
                Permission Denied
            </div>
        )
    }
}
export default PermissionDenied
